<script setup lang="ts">
import { T } from "@/classes/i18n"
import requestHandler from "@/queries/requests"
import { useStore } from "@/store/vuex.store"
import { createPopper, type Placement, type VirtualElement } from "@popperjs/core"
import Inputmask from "inputmask"
import { debounce } from "throttle-debounce"
import { computed, h, onMounted, onUnmounted, ref } from "vue"
import vSelect from "vue-select"
import loaderComponent from "../components/loader.vue"
import tooltipComponent from "../components/tooltip.vue"

// Legacy select 2 queries
const select2AppLookUpId = async function (
    searchString: string,
    types: ("software" | "iPadSoftware" | "tvSoftware")[] = ["software", "iPadSoftware"]
) {
    let result: Array<selectOption> = []
    try {
        let response = await requestHandler.request(
            "GET",
            "https://itunes.apple.com/lookup?id=" +
                searchString +
                "&country=DE&media=software&entity=" +
                types.join(",")
        )
        if (response.results) {
            result = response.results.map((result: any) => {
                return {
                    id: result.trackId,
                    text: result.trackName
                }
            })
        }
    } catch (e: unknown) {
        console.error(e)
    }
    return result
}
const select2AppSearch = async function (
    searchString: string,
    types: ("software" | "iPadSoftware" | "tvSoftware")[] = ["software", "iPadSoftware"]
) {
    let result: Array<selectOption> = []
    let response = await requestHandler.request(
        "GET",
        "https://itunes.apple.com/search?term=" +
            searchString +
            "&country=DE&media=software&entity=" +
            types.join(",")
    )
    if (response.results) {
        result = response.results.map((result: any) => {
            return {
                id: result.bundleId,
                text: result.trackName
            }
        })
    }
    return result
}
const select2AppSearchPlusNative = async function (
    searchString: string,
    types: ("software" | "iPadSoftware" | "tvSoftware")[] = ["software", "iPadSoftware"]
) {
    let result: Array<selectOption> = []
    let response = await requestHandler.request(
        "GET",
        "https://itunes.apple.com/search?term=" +
            searchString +
            "&country=DE&media=software&entity=" +
            types.join(",")
    )
    if (response.results) {
        result = response.results.map((result: any) => {
            return {
                id: result.bundleId,
                text: result.trackName
            }
        })
    }

    if (types.indexOf("software") != -1) {
        let nativeAppOptions = useStore().state.resources?.apple?.nativeAppInfos?.apps.map(
            (item: any) => {
                return {
                    text: item.trackName,
                    id: item.bundleId
                }
            }
        )
        result = result.concat(nativeAppOptions).filter((option: any) => {
            return (
                option.id.toLowerCase().indexOf(searchString) != -1 ||
                (option?.text || "").toLowerCase().indexOf(searchString) != -1 ||
                (option?.text || "").toLowerCase().indexOf(searchString.replaceAll("*", "")) != -1
            )
        })
    }
    if (types.indexOf("tvSoftware") != -1) {
        let nativeTvOSAppOptions = useStore().state.resources?.apple?.tvOsNativeApps?.apps.map(
            (item: any) => {
                return {
                    text: item.trackName,
                    id: item.bundleId
                }
            }
        )
        result = result.concat(nativeTvOSAppOptions).filter((option: any) => {
            return (
                option.id.toLowerCase().indexOf(searchString) != -1 ||
                (option?.text || "").toLowerCase().indexOf(searchString) != -1 ||
                (option?.text || "").toLowerCase().indexOf(searchString.replaceAll("*", "")) != -1
            )
        })
    }

    return result
}
const select2AppSearchPlusAllWebClips = async function (
    searchString: string,
    types: ("software" | "iPadSoftware" | "tvSoftware")[] = ["software", "iPadSoftware"]
) {
    let result: Array<selectOption> = []
    let response = await requestHandler.request(
        "GET",
        "https://itunes.apple.com/search?term=" +
            searchString +
            "&country=DE&media=software&entity=" +
            types.join(",")
    )
    if (response.results) {
        result = response.results.map((result: any) => {
            return {
                id: result.bundleId,
                text: result.trackName + " (" + result.bundleId + ")"
            }
        })
    }
    result.push({
        text: "Web clips",
        id: "com.apple.webapp"
    })
    let nativeAppOptions = useStore().state.resources?.apple?.nativeAppInfos?.apps.map(
        (item: any) => {
            return {
                text: item.trackName + " (" + item.bundleId + ")",
                id: item.bundleId
            }
        }
    )
    result = result.concat(nativeAppOptions).filter((option: any) => {
        // skip preferences, see https://redmine.intern.securepoint.de/issues/48139
        if (option?.id === "com.apple.Preferences") {
            return false
        }

        return (
            option.id.toLowerCase().indexOf(searchString) != -1 ||
            (option?.text || "").toLowerCase().indexOf(searchString) != -1 ||
            (option?.text || "").toLowerCase().indexOf(searchString.replaceAll("*", "")) != -1
        )
    })
    return result
}

const OpenIndicator = h("i", { class: "fa fa-angle-down" })
const Deselect = h("i", { class: "fa fa-times" })
//@ts-ignore
vSelect.props.components.default = () => ({ OpenIndicator, Deselect })

// DEFINE COMPONENT PROPS
const props = withDefaults(
    defineProps<{
        modelValue: null | string | number | Array<string | number>
        selectOptions: Array<selectOption>
        multiple?: boolean
        tags?: boolean
        loading?: boolean
        query?: (searchString: string) => Promise<Array<selectOption>>
        inputMask?: Inputmask.Options | "tags"
        select2Options?:
            | "select2Tags"
            | "select2IpMask"
            | "select2AppSearchPlusAllWebClips"
            | "select2AppSearch"
            | "select2AppLookUpId"
            | "select2AppSearchTvOs"
        onSelecting?: Function
        id?: string
        useTwoRows?: boolean
        searchPlaceholder?: string
        noTranslations?: true
        noSort?: true
        clearable?: boolean
        selectable?: boolean
    }>(),
    {
        modelValue: null,
        selectOptions: () => [],
        selectable: true
    }
)

const closeOnSelect = ref(<boolean>true)
// VALUE HANDLING
const emit = defineEmits(["update:modelValue", "change"])
const value = computed({
    get: () => props.modelValue,
    set: (value) => {
        emit("update:modelValue", value)
        emit("change", value)
    }
})

// OPTIONS (MERGE AJAX + GIVEN OPTIONS)
const foundOptions = ref(<Array<selectOption>>[])
const allOptions = ref(<Array<selectOption>>[])

const options = computed(() => {
    let result: Array<selectOption> = []

    ;(foundOptions.value || []).forEach((option) => {
        result.push(option)
    })
    ;(props.selectOptions || []).forEach((option) => {
        if (
            !result.some((thisOption) => {
                return thisOption.id === option.id
            })
        ) {
            result.push(option)
        }
    })
    ;(allOptions.value || []).forEach((option) => {
        if (
            !result.some((thisOption) => {
                return thisOption.id === option.id
            })
        ) {
            result.push(option)
        }
    })

    // Translations
    if (props.noTranslations !== true || props.tags === false) {
        result = result.map((option: selectOption) => {
            if (option?.text) {
                option.text = T(option.text)
            }
            return option
        })
    }

    const foundGroups = result.filter((option) => {
        return option?.type == "groupName"
    })

    // default Sorting
    if (props.noSort !== true) {
        result = result.sort((a, b) => {
            return (a?.text || "").localeCompare(b?.text || "")
        })
    }

    if (foundGroups.length > 0) {
        let groupedResult: selectOption[] = []
        foundGroups.forEach((groupOption) => {
            const groupId = groupOption.id
            groupedResult.push(groupOption)

            const childOptions = result.filter((option) => {
                return option.groupId == groupId
            })
            groupedResult.push(...childOptions)
        })

        result = groupedResult
    }
    return result
})

// AJAX
const execSearch = debounce(
    1000,
    async (searchString: string, loading: (loading: boolean) => void) => {
        if (searchString == searchStringRef.value) {
            let result: any = []

            searchString = searchString.toLowerCase()
            if (props.query != undefined) {
                result = (await props?.query?.(searchString)) || []
            } else if (props.select2Options == "select2AppSearch") {
                result = (await select2AppSearch(searchString)) || []
            } else if (props.select2Options == "select2AppSearchPlusAllWebClips") {
                result = await select2AppSearchPlusAllWebClips(searchString)
            } else if (props.select2Options == "select2AppSearchTvOs") {
                result = await select2AppSearchPlusNative(searchString, ["tvSoftware"])
            } else if (props.select2Options == "select2AppLookUpId") {
                result = await select2AppLookUpId(searchString)
            }
            if (result.length) {
                ;(result || []).forEach((option: selectOption) => {
                    if (
                        !allOptions.value.some((thisOption) => {
                            return thisOption.id === option.id
                        })
                    ) {
                        allOptions.value.push(option)
                    }
                    if (
                        !foundOptions.value.some((thisOption) => {
                            return thisOption.id === option.id
                        })
                    ) {
                        foundOptions.value.push(option)
                    }
                })
            } else {
                foundOptions.value = []
            }
        }
        loading(false)
    }
)
const searchStringRef = ref("")
const ajaxSearch = async (searchString: string, loading: (loading: boolean) => void) => {
    searchStringRef.value = searchString
    if (
        (props.query != undefined ||
            props.select2Options == "select2AppSearch" ||
            props.select2Options == "select2AppSearchPlusAllWebClips" ||
            props.select2Options == "select2AppLookUpId" ||
            props.select2Options == "select2AppSearchTvOs") &&
        searchString.length >= 3
    ) {
        loading(true)
        execSearch(searchString, loading)
    } else {
        loading(false)
    }
}

const selectComponent = ref(<any>null)

const useTags = computed(() => {
    return (
        props.tags == true ||
        props.select2Options == "select2Tags" ||
        props.select2Options == "select2IpMask"
    )
})

const placement = ref(<Placement>"bottom-start")
const withPopper = (
    dropdownList: HTMLElement,
    component: {
        $refs: { toggle: Element | VirtualElement }
        $el: { classList: { toggle: (arg0: string, arg1: boolean) => void } }
    },
    { width }: any
) => {
    dropdownList.style.width = width
    const popper = createPopper(component.$refs.toggle, dropdownList, {
        placement: placement.value,
        modifiers: [
            {
                name: "offset",
                options: {
                    offset: [0, -1]
                }
            },
            {
                name: "toggleClass",
                enabled: true,
                phase: "write",
                fn({ state }) {
                    component.$el.classList.toggle("drop-up", state.placement === "top")
                }
            }
        ]
    })

    return () => popper.destroy()
}

function onKeyUp(event: KeyboardEvent) {
    if (event.key === "Control" || event.key === "Meta") {
        closeOnSelect.value = true
    }
}

onUnmounted(() => {
    document.removeEventListener("keyup", onKeyUp)
})

onMounted(() => {
    document.addEventListener("keyup", onKeyUp)

    const searchInput = selectComponent.value.$refs.search
    // Inputmasks
    if (props.inputMask === "tags") {
        Inputmask({
            mask: "*{1,64}",
            placeholder: " ",
            defions: {
                "*": {
                    validator: function (
                        chrs: string,
                        maskset: any,
                        pos: any,
                        strict: any,
                        opts: any
                    ) {
                        return new RegExp("^[A-Za-z0-9\._-]+$").test(chrs)
                    }
                }
            }
        } as Inputmask.Options).mask(searchInput)
    } else if (props.inputMask) {
        Inputmask(props.inputMask).mask(searchInput)
    } else if (props.select2Options == "select2IpMask") {
        Inputmask({
            mask: "i[i[i]].i[i[i]].i[i[i]].i[i[i]]/a[a]",
            definitions: {
                i: {
                    validator: function (chrs, maskset, pos, strict, opts) {
                        return (
                            pos - 1 > -1 && "." !== maskset.buffer[pos - 1]
                                ? ((chrs = maskset.buffer[pos - 1] + chrs),
                                  (chrs =
                                      pos - 2 > -1 && "." !== maskset.buffer[pos - 2]
                                          ? maskset.buffer[pos - 2] + chrs
                                          : "0" + chrs))
                                : (chrs = "00" + chrs),
                            new RegExp("25[0-5]|2[0-4][0-9]|[01][0-9][0-9]").test(chrs)
                        )
                    }
                },
                a: {
                    validator: function (chrs, maskset, pos, strict, opts) {
                        if (pos - 1 > -1 && "/" !== maskset.buffer[pos - 1]) {
                            chrs = maskset.buffer[pos - 1] + chrs
                        } else {
                            chrs = "0" + chrs
                        }

                        return new RegExp("0[0-9]|1[0-9]|2[0-9]|3[0-2]").test(chrs)
                    },
                    cardinality: 1
                }
            },
            inputmode: "numeric"
        } as Inputmask.Options).mask(searchInput)
    }
})

const updateModelValue = () => {
    const newValue: any[] = []
    let valueIDs = props.modelValue
    if (Array.isArray(valueIDs)) {
        options.value.forEach((option) => {
            if ((<Array<any>>valueIDs).indexOf(option.id) != -1) {
                newValue.push(option)
            }
        })
        selectComponent.value.updateValue(newValue)
    }
}

const optionFilter = (options: selectOption[], search: string) => {
    return search.length
        ? options.filter((option: selectOption) => {
              //@ts-ignore
              return Object.keys(option).some((optionProperty: keyof typeof option) => {
                  if (["boolean"].indexOf(typeof option[optionProperty]) == -1) {
                      return (
                          String(option[optionProperty])
                              .toLowerCase()
                              .indexOf(search.toLowerCase()) != -1
                      )
                  } else {
                      return false
                  }
              })
          })
        : options
}

const onSelect = (event: any) => {
    // close tooltip
    if (event?.tooltip?.customId) {
        let tooltipId = event.tooltip.customId
        let contentEl = document.getElementById("tooltip#" + tooltipId)
        if (contentEl) {
            contentEl.remove()
        }
    }
}

function getNoOptionsText(search: string, searching: boolean, loading: boolean) {
    if (loading) {
        return T("Loading...")
    } else if (searching && search.length < 3) {
        return T("Enter at least 3 characters in order to Search")
    } else if (useTags.value) {
        return T(props.searchPlaceholder || "Enter text to add Tags")
    } else {
        return T("No options found")
    }
}

function isSelectable(option: selectOption) {
    if (props.selectable === false) {
        return false
    }

    if (option.disabled === true) {
        return false
    }

    return true
}

defineExpose({
    updateModelValue
})
</script>

<template name="input-select">
    <v-select
        class="selectComponent"
        :class="{ twoRows: props.useTwoRows }"
        ref="selectComponent"
        :options="options"
        v-model="value"
        label="text"
        :loading="loading"
        :reduce="
            (option: selectOption) => {
                if (option.id != undefined) {
                    return option.id
                } else {
                    return option?.text
                }
            }
        "
        :clearable="clearable == true ? true : false"
        :multiple="multiple"
        :filter="optionFilter"
        :appendToBody="true"
        :calculate-position="withPopper"
        :pushTags="useTags || props.query != undefined"
        :taggable="useTags || props.query != undefined"
        :create-option="
            useTags || props.query != undefined
                ? (tag: string) => {
                      tag = typeof tag == 'string' ? tag.trim() : tag
                      if (props.select2Options == 'select2IpMask' && tag.indexOf('_') != -1) {
                      } else {
                          if (
                              !(allOptions || []).some((option) => {
                                  return option.id == tag
                              }) &&
                              tag != ''
                          ) {
                              return { id: tag, text: tag }
                          }
                      }
                  }
                : undefined
        "
        :selectable="isSelectable"
        @search="ajaxSearch"
        @option:selecting="
            (event: any) => {
                console.log(event)
                props.onSelecting ? props.onSelecting?.(event) : onSelect(event)
            }
        "
        :closeOnSelect="closeOnSelect"
        :map-keydown="
            (map, vm) => {
                return {
                    ...map,
                    // Meta (CMD on macOS) key
                    91: (event: KeyboardEvent) => {
                        closeOnSelect = false
                    },
                    // Ctrl key
                    17: (event: KeyboardEvent) => {
                        closeOnSelect = false
                    }
                }
            }
        "
    >
        <template #no-options="{ search, searching, loading }">
            {{ getNoOptionsText(search, searching, loading) }}
        </template>

        <template #option="{ id, text, icon, type, tooltip }">
            <template v-if="type">
                <strong>
                    <i v-if="icon" class="fa-fw margin-xs-r" :class="icon"></i>{{ text }}
                </strong>
            </template>
            <template v-else>
                <component
                    :is="tooltip != undefined ? tooltipComponent : 'span'"
                    :tooltip="(<selectOption['tooltip']>tooltip)?.content || ''"
                    :htmlTooltip="(<selectOption['tooltip']>tooltip)?.htmlTooltip || false"
                    :customId="(<selectOption['tooltip']>tooltip)?.customId || undefined"
                    :isTag="'span'"
                >
                    <i v-if="icon" class="fa-fw margin-xs-r" :class="icon"></i>{{ text }}
                </component>
            </template>
        </template>

        <template #selected-option="{ id, text, icon, locked }">
            <span :class="{ locked: locked }"
                ><i v-if="icon" class="fa-fw margin-xs-r" :class="icon"></i>{{ text }}</span
            >
        </template>

        <template #spinner="{ loading }">
            <loader-component v-if="loading" class="color-red"></loader-component>
        </template>
    </v-select>
</template>

<style lang="scss">
.v-select.selectComponent {
    width: 100%;
    &.vs--disabled {
        opacity: 0.6;
        .vs__open-indicator {
            display: none !important;
        }
    }
    .vs__dropdown-toggle input.vs__search {
        border: none !important;
        box-shadow: none;
        background: none;
        display: flex;
        -webkit-appearance: none;
        -moz-appearance: none;
        appearance: none;
        border: 1px solid transparent;
        border-left: none;
        outline: none;
        margin: 4px 0 0;
        padding: 0 7px;
        background: none;
        box-shadow: none;
        width: 0 !important;
        max-width: 100%;
        flex-grow: 1;
        z-index: 1;
        font-size: 1em;
        min-height: 25px;
    }

    .vs__actions {
        .btn,
        button {
            margin: 0px 5px 0px 8px;
            stroke: none;
            color: rgba(0, 0, 0, 0.7);
            opacity: 0.4;

            &.vs__clear {
                display: block;
            }

            &:hover {
                opacity: 0.8;
                color: #e74c3c;
            }
        }
        .vs__open-indicator {
            //display: inline-block;
            //width: 12px;
            //height: 1em;
            //background-image: url("data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABQAAAAhCAYAAADZPosTAAAAdklEQVRIS+2S0Q3AIAgFYbOO4hpMwBhNJ6WxSaNpFA0Yf4rfcHkeD2Hxw8U8CKDfaDgMhwYDe2rDzAkRTy2giFxElL4z3YQatAfLcPXLLagGGwLzQA0dwaaALxQAjpazaYeGxjwre2pjTRcJPebKblzZ7/GHDm+LjCQiegl+qAAAAABJRU5ErkJggg==");
            background-position: center;
            background-repeat: no-repeat;
            opacity: 0.6;
        }
    }

    .vs__selected {
        overflow: hidden;
    }
    .vs__selected > span.locked {
        opacity: 0.8;
    }
    .vs__selected > span.locked + button {
        display: none;
    }

    .vs__dropdown-toggle {
        border: 1px solid rgba(0, 0, 0, 0.125);
        border-radius: 2px;
        background: rgba(0, 0, 0, 0.01);
        border-radius: 2px;
        font-size: 1em;
        box-shadow: 0 0 6px -1px rgba(0, 0, 0, 0.1) inset;
        font-family: "Arial";
        line-height: 1.25em;
        overflow: hidden;
    }
}

.vs__dropdown-menu {
    background: darken(#545861, 10%);
    color: #fff;
    position: absolute;
    left: 0;
    > li {
        padding: 8px;
        &.vs__dropdown-option--highlight {
            background: #e74c3c;
        }
        &.vs__dropdown-option--selected {
            cursor: default;
            opacity: 0.2;
            display: none;
            &.vs__dropdown-option--highlight {
                background: none;
            }
        }
    }
    .vs__dropdown-option--disabled {
        background: none;
        color: inherit;
        opacity: 0.5;
    }
}

.v-select.selectComponent.vs--multiple {
    .vs__selected-options {
        flex-wrap: wrap;

        .vs__selected {
            display: block;
            background-color: #f1f1f1;
            border: 1px solid rgba(0, 0, 0, 0.125);
            border-radius: 2px;
            cursor: default;
            float: left;
            margin-right: 5px;
            margin-top: 5px;
            padding: 3px 5px;
            button {
                fill: rgba(0, 0, 0, 0.6);
                color: rgba(0, 0, 0, 0.3);
                &:hover {
                    color: rgba(0, 0, 0, 0.6);
                }

                svg {
                    scale: 0.75;
                }
            }
        }
    }
}

.v-select.selectComponent.twoRows > div {
    height: 5.4em;
    .vs__selected {
        height: 2.1em;
    }
}

.darkmode {
    .vs__selected {
        color: #fff9;
    }
    .v-select.selectComponent {
        .vs__dropdown-toggle {
            border: 1px solid rgba(255, 255, 255, 0.125);
            background: rgba(0, 0, 0, 0.05);
            box-shadow: 0 0 6px -1px rgba(0, 0, 0, 0.1) inset;
        }
    }
    .v-select.selectComponent.vs--multiple {
        .vs__selected-options {
            .vs__selected {
                background-color: #393c42;
                border: 1px solid rgba(255, 255, 255, 0.06);
                color: rgba(255, 255, 255, 0.6);
                button {
                    fill: rgba(255, 255, 255, 0.6);
                }
            }
        }
    }

    .vs__dropdown-menu {
        background: darken(#545861, 10%);
        color: #fff;

        > li {
            padding: 8px;
            &.vs__dropdown-option--highlight {
                background: #e74c3c;
                color: #fff;
            }
        }
    }
    .vs__actions {
        button,
        .btn {
            i {
                color: rgba(255, 255, 255, 0.7);
            }
            &:hover {
                i {
                    opacity: 1;
                    color: #e74c3c;
                }
            }
        }
    }
}
</style>
