<template>
    <tabs :active-tab="activeTab" :tabs="tabs" :disable-content-box="tabsWithoutBox.has(activeTab)">
        <div v-if="activeTab === 'mdm'">
            <p
                v-html="
                    T(
                        'Create a <b>configuration profile</b> for your Android or Apple devices in Mobile Security.'
                    )
                "
            ></p>

            <p v-if="lang === 'de'">
                Wählen Sie im Bereich <b>Cloud Shield</b> das Profil
                <span class="highlight">{{ name }}</span> aus und weisen Sie es denen zu schützenden
                Geräten zu.
            </p>
            <p v-else>
                In the <b>Cloud Shield</b> section, select the profile
                <span class="highlight">{{ name }}</span> and assign it to the devices to be
                protected.
            </p>
        </div>

        <div v-if="activeTab === 'app'" class="content-container">
            <div class="text-content">
                <p>
                    {{
                        T(
                            "Scannen Sie einfach den nebenstehenden QR-Code mit der Securepoint Cloud Shield App. Laden Sie die App dafür aus dem App Store für Apple-Geräte oder aus dem Google Play Store für Android-Geräte herunter."
                        )
                    }}
                </p>

                <div class="store-buttons">
                    <button v-on:click="openAppStore" class="btn btn-primary">
                        <i class="fab fa-app-store"></i>
                        {{ T("Apple App herunterladen") }}
                    </button>

                    <button v-on:click="openPlayStore" class="margin-xs-l-2 btn btn-primary">
                        <i class="fab fa-google-play"></i>

                        {{ T("Android App herunterladen") }}
                    </button>
                </div>

                <p v-if="lang === 'de'" class="padding-xs-t">
                    Alternativ geben Sie die Konfigurations-ID
                    <span class="highlight">{{ shortId }}</span> in den Einstellungen der Cloud
                    Shield App auf Ihrem Android- oder Apple-Gerät ein und aktivieren Sie den
                    Schieberegler.
                </p>
                <p v-else class="padding-xs-t">
                    Alternatively, enter the configuration ID
                    <span class="highlight">{{ shortId }}</span> in the settings of the Cloud Shield
                    app on your Android or Apple device and activate the slider.
                </p>
            </div>
            <div class="qr-code">
                <img
                    :src="`/sms-mgt-api/api/2.0/tenants/${accountId}.sms/secure-dns/configs/${id}/qr-code`"
                    alt="QR-Code"
                />
            </div>
        </div>

        <div v-if="activeTab === 'other'">
            <div class="row">
                <div class="col-xs-24">
                    <p>
                        {{
                            T(
                                "Falls Sie Securepoint Cloud Shield direkt im Netzwerk für alle verbundenen Geräte aktivieren möchten, können Sie es auf Ihrem Router konfigurieren. Nutzen Sie dazu einen der folgenden Endpunkte, abhängig von den spezifischen Einstellungen Ihres Routers."
                            )
                        }}
                    </p>

                    <div class="row padding-xs-y">
                        <div class="col-xs-24 col-md-12">
                            <p>{{ T("Für DNS-over-HTTPS (DoH) benutzen Sie bitte:") }}</p>
                            <div style="display: flex; align-items: center">
                                <p class="highlight">{{ dohClientName }}</p>
                            </div>
                        </div>
                    </div>

                    <div class="row padding-xs-b">
                        <div class="col-xs-24 col-md-12">
                            <p>{{ T("Für DNS-over-TLS (DoT) benutzen Sie bitte:") }}</p>
                            <div style="display: flex; align-items: center">
                                <p class="highlight">{{ dotClientName }}</p>
                            </div>
                        </div>
                    </div>
                </div>
            </div>

            <div class="row">
                <div class="col-xs-24 padding-xs-t">
                    <p>
                        {{
                            T(
                                "Optional können Sie hier einen eigenen Namen für Ihr Gerät festlegen. Dadurch wird die oben angezeigte Adresse entsprechend angepasst, sodass Ihr Gerät in Statistiken und Protokollen leichter identifiziert werden kann."
                            )
                        }}
                    </p>

                    <div class="row padding-xs-y">
                        <div class="col-xs-24 col-md-12">
                            <input
                                v-model="clientNameInput"
                                type="text"
                                maxlength="25"
                                :placeholder="T('Example: John\'s Device')"
                            />
                            <span
                                v-if="clientNameError"
                                class="error-bubble label bg-red margin-xs-t padding-xs-y"
                            >
                                <i class="fa-solid fa-triangle-exclamation" />
                                {{ T(clientNameError) }}
                            </span>
                        </div>
                    </div>

                    <p>
                        {{
                            T(
                                "Hinweis: Der eingegebene Gerätename wird nicht dauerhaft im Profil gespeichert. Er dient ausschließlich dazu, die oben angezeigte URL (für DoH) bzw. den Hostnamen (für DoT) temporär anzupassen. So kann dasselbe Profil auf mehreren Geräten verwendet werden – jeweils mit individuellem Namen zur besseren Identifikation in Statistiken und Protokollen."
                            )
                        }}
                    </p>
                </div>
            </div>
        </div>

        <div v-if="activeTab === 'apple'">
            <p>
                {{
                    T(
                        "Falls Sie unsere App nicht nutzen möchten und Securepoint Cloud Shield dauerhaft auf Ihrem Apple-Gerät aktivieren möchten, können Sie hier eine signierte Mobileconfig herunterladen und installieren."
                    )
                }}
            </p>

            <div class="row padding-xs-y">
                <div class="col-xs-24 col-md-12">
                    <button
                        v-on:click.prevent="download()"
                        class="btn btn-primary"
                        style="flex-shrink: 0"
                        :title="T('Download mobileconfig')"
                    >
                        <i class="fas fa-download"></i>
                        {{ T("Download mobileconfig") }}
                    </button>
                </div>
            </div>

            <p>
                {{
                    T(
                        "Optional können Sie hier einen eigenen Namen für Ihr Gerät festlegen. Dadurch wird die oben angezeigte Adresse entsprechend angepasst, sodass Ihr Gerät in Statistiken und Protokollen leichter identifiziert werden kann."
                    )
                }}
            </p>

            <div class="row padding-xs-y">
                <div class="col-xs-24 col-md-12">
                    <input
                        v-model="clientNameInput"
                        type="text"
                        maxlength="25"
                        :placeholder="T('Example: John\'s Device')"
                    />
                    <span
                        v-if="clientNameError"
                        class="error-bubble label bg-red margin-xs-t padding-xs-y"
                    >
                        <i class="fa-solid fa-triangle-exclamation" />
                        {{ T(clientNameError) }}
                    </span>
                </div>
            </div>

            <p>
                {{
                    T(
                        "Hinweis: Der eingegebene Gerätename wird nicht dauerhaft im Profil gespeichert. Er dient ausschließlich dazu, die oben angezeigte URL (für DoH) bzw. den Hostnamen (für DoT) temporär anzupassen. So kann dasselbe Profil auf mehreren Geräten verwendet werden – jeweils mit individuellem Namen zur besseren Identifikation in Statistiken und Protokollen."
                    )
                }}
            </p>
        </div>

        <div v-if="activeTab === 'android'">
            <p>
                {{
                    T(
                        "Wenn Sie unsere Android-App nicht nutzen möchten, können Sie Securepoint Cloud Shield manuell auf Ihrem Android-Gerät einrichten. Folgen Sie dazu diesen Schritten:"
                    )
                }}
            </p>

            <ol>
                <li>{{ T("Öffnen Sie die Einstellungen auf Ihrem Android-Gerät.") }}</li>
                <li>
                    {{ T("Navigieren Sie zu") }} <b>{{ T("Netzwerk & Internet") }}</b
                    >.
                </li>
                <li>
                    {{ T("Wählen Sie") }} <b>{{ T("Privates DNS") }}</b
                    >.
                </li>
                <li>
                    {{ T("Tippen Sie auf") }}
                    <b>{{ T("Hostname des Anbieters des privaten DNS") }}</b
                    >.
                </li>
                <li>
                    {{ T("Geben Sie folgende Adresse ein:") }}
                    <span class="highlight">{{ dotClientName }}</span>
                </li>
            </ol>

            <div class="row">
                <div class="col-xs-24 padding-xs-t">
                    <p>
                        {{
                            T(
                                "Optional können Sie hier einen eigenen Namen für Ihr Gerät festlegen. Dadurch wird die oben angezeigte Adresse entsprechend angepasst, sodass Ihr Gerät in Statistiken und Protokollen leichter identifiziert werden kann."
                            )
                        }}
                    </p>

                    <div class="row padding-xs-y">
                        <div class="col-xs-24 col-md-12">
                            <input
                                v-model="clientNameInput"
                                type="text"
                                maxlength="25"
                                :placeholder="T('Example: John\'s Device')"
                            />
                            <span
                                v-if="clientNameError"
                                class="error-bubble label bg-red margin-xs-t padding-xs-y"
                            >
                                <i class="fa-solid fa-triangle-exclamation" />
                                {{ T(clientNameError) }}
                            </span>
                        </div>
                    </div>

                    <p>
                        {{
                            T(
                                "Hinweis: Der eingegebene Gerätename wird nicht dauerhaft im Profil gespeichert. Er dient ausschließlich dazu, die oben angezeigte URL (für DoH) bzw. den Hostnamen (für DoT) temporär anzupassen. So kann dasselbe Profil auf mehreren Geräten verwendet werden – jeweils mit individuellem Namen zur besseren Identifikation in Statistiken und Protokollen."
                            )
                        }}
                    </p>
                </div>
            </div>
        </div>

        <div v-if="activeTab === 'browser'">
            <tabs :active-tab="activeBrowserTab" :tabs="browserTabs" menu-type="vertical">
                <div v-if="activeBrowserTab === 'chrome'">
                    <p>
                        {{
                            T(
                                "Falls Sie die Einstellungen nicht systemweit vornehmen möchten oder können, können Sie Securepoint Cloud Shield direkt in Ihrem Chrome-Browser konfigurieren. Folgen Sie dazu diesen Anweisungen:"
                            )
                        }}
                    </p>

                    <ol>
                        <li>{{ T("Öffnen Sie Chrome.") }}</li>
                        <li v-if="lang === 'de'">
                            Klicken Sie auf das Menüsymbol <i class="fas fa-ellipsis-v"></i> in der
                            oberen rechten Ecke.
                        </li>
                        <li v-else>
                            Click on the menu icon <i class="fas fa-ellipsis-v"></i> in the top
                            right corner.
                        </li>

                        <li v-html="T('Wählen Sie <b>Einstellungen</b> aus.')"></li>
                        <li v-html="T('Wählen Sie <b>Datenschutz und Sicherheit</b> aus.')"></li>
                        <li v-html="T('Wählen Sie <b>Sicherheit</b> aus.')"></li>
                        <li
                            v-html="
                                T(
                                    'Aktivieren Sie <b>Sicheres DNS verwenden</b> unter dem Menüpunkt <b>Erweitert</b>'
                                )
                            "
                        ></li>
                        <li
                            v-html="
                                T(
                                    'Bei <b>DNS-Anbieter auswählen</b> wählen Sie <b>Benutzerdefinierten DNS-Dienstanbieter hinzufügen</b>'
                                )
                            "
                        ></li>
                        <li>
                            {{ T("Geben Sie folgende Adresse ein:") }}
                            <span class="highlight">{{ dohClientName }}</span>
                        </li>
                    </ol>

                    <p>
                        {{
                            T(
                                "Optional können Sie hier einen eigenen Namen für Ihr Gerät festlegen. Dadurch wird die oben angezeigte Adresse entsprechend angepasst, sodass Ihr Gerät in Statistiken und Protokollen leichter identifiziert werden kann."
                            )
                        }}
                    </p>

                    <div class="row padding-xs-y">
                        <div class="col-xs-24 col-md-12">
                            <input
                                v-model="clientNameInput"
                                type="text"
                                maxlength="25"
                                :placeholder="T('Example: John\'s Device')"
                            />
                            <span
                                v-if="clientNameError"
                                class="error-bubble label bg-red margin-xs-t padding-xs-y"
                            >
                                <i class="fa-solid fa-triangle-exclamation" />
                                {{ T(clientNameError) }}
                            </span>
                        </div>
                    </div>

                    <p>
                        {{
                            T(
                                "Hinweis: Der eingegebene Gerätename wird nicht dauerhaft im Profil gespeichert. Er dient ausschließlich dazu, die oben angezeigte URL (für DoH) bzw. den Hostnamen (für DoT) temporär anzupassen. So kann dasselbe Profil auf mehreren Geräten verwendet werden – jeweils mit individuellem Namen zur besseren Identifikation in Statistiken und Protokollen."
                            )
                        }}
                    </p>
                </div>

                <div v-if="activeBrowserTab === 'firefox'">
                    <p>
                        {{
                            T(
                                "Falls Sie die Einstellungen nicht systemweit vornehmen möchten oder können, können Sie Securepoint Cloud Shield direkt in Ihrem Firefox-Browser konfigurieren. Folgen Sie dazu diesen Anweisungen:"
                            )
                        }}
                    </p>

                    <ol>
                        <li>{{ T("Öffnen Sie Firefox.") }}</li>
                        <li v-if="lang === 'de'">
                            Klicken Sie auf das Menüsymbol <i class="fas fa-bars"></i> in der oberen
                            rechten Ecke.
                        </li>
                        <li v-else>
                            Click on the menu icon <i class="fas fa-bars"></i> in the top right
                            corner.
                        </li>

                        <li v-html="T('Wählen Sie <b>Einstellungen</b> aus.')"></li>
                        <li v-html="T('Geben Sie in der Suche <b>DNS</b> ein.')"></li>
                        <li v-html="T('Wählen Sie <b>Maximaler Schutz</b> aus.')"></li>
                        <li
                            v-html="
                                T(
                                    'Unter dem Menüpunkt <b>Anbieter auswählen</b> wählen Sie <b>Benutzerdefiniert</b> aus.'
                                )
                            "
                        ></li>

                        <li>
                            {{ T("Geben Sie folgende Adresse ein:") }}
                            <span class="highlight">{{ dohClientName }}</span>
                        </li>
                    </ol>

                    <p>
                        {{
                            T(
                                "Optional können Sie hier einen eigenen Namen für Ihr Gerät festlegen. Dadurch wird die oben angezeigte Adresse entsprechend angepasst, sodass Ihr Gerät in Statistiken und Protokollen leichter identifiziert werden kann."
                            )
                        }}
                    </p>

                    <div class="row padding-xs-y">
                        <div class="col-xs-24 col-md-12">
                            <input
                                v-model="clientNameInput"
                                type="text"
                                maxlength="25"
                                :placeholder="T('Example: John\'s Device')"
                            />
                            <span
                                v-if="clientNameError"
                                class="error-bubble label bg-red margin-xs-t padding-xs-y"
                            >
                                <i class="fa-solid fa-triangle-exclamation" />
                                {{ T(clientNameError) }}
                            </span>
                        </div>
                    </div>

                    <p>
                        {{
                            T(
                                "Hinweis: Der eingegebene Gerätename wird nicht dauerhaft im Profil gespeichert. Er dient ausschließlich dazu, die oben angezeigte URL (für DoH) bzw. den Hostnamen (für DoT) temporär anzupassen. So kann dasselbe Profil auf mehreren Geräten verwendet werden – jeweils mit individuellem Namen zur besseren Identifikation in Statistiken und Protokollen."
                            )
                        }}
                    </p>
                </div>

                <div v-if="activeBrowserTab === 'edge'">
                    <p>
                        {{
                            T(
                                "Falls Sie die Einstellungen nicht systemweit vornehmen möchten oder können, können Sie Securepoint Cloud Shield direkt in Ihrem Edge-Browser konfigurieren. Folgen Sie dazu diesen Anweisungen:"
                            )
                        }}
                    </p>

                    <ol>
                        <li>{{ T("Öffnen Sie Edge.") }}</li>
                        <li v-if="lang === 'de'">
                            Klicken Sie auf das Menüsymbol <i class="fas fa-ellipsis"></i> in der
                            oberen rechten Ecke.
                        </li>
                        <li v-else>
                            Click on the menu icon <i class="fas fa-ellipsis"></i> in the top right
                            corner.
                        </li>

                        <li v-html="T('Wählen Sie <b>Einstellungen</b> aus.')"></li>
                        <li
                            v-html="
                                T(
                                    'Klicken Sie in der linken Leiste auf <b>Datenschutz, Suche und Dienste</b>.'
                                )
                            "
                        ></li>
                        <li v-html="T('Scrollen Sie bis zum Bereich <b>Sicherheit</b>.')"></li>
                        <li
                            v-html="
                                T(
                                    'Unter dem Menüpunkt <b>Verwenden Sie sicheres DNS, um anzugeben, wie die Netzwerkadresse für Websites nachzuschlagen ist</b> wählen Sie <b>Einen Dienstanbieter wählen</b> aus.'
                                )
                            "
                        ></li>

                        <li>
                            {{ T("Geben Sie folgende Adresse ein:") }}
                            <span class="highlight">{{ dohClientName }}</span>
                        </li>
                    </ol>

                    <p>
                        {{
                            T(
                                "Optional können Sie hier einen eigenen Namen für Ihr Gerät festlegen. Dadurch wird die oben angezeigte Adresse entsprechend angepasst, sodass Ihr Gerät in Statistiken und Protokollen leichter identifiziert werden kann."
                            )
                        }}
                    </p>

                    <div class="row padding-xs-y">
                        <div class="col-xs-24 col-md-12">
                            <input
                                v-model="clientNameInput"
                                type="text"
                                maxlength="25"
                                :placeholder="T('Example: John\'s Device')"
                            />
                            <span
                                v-if="clientNameError"
                                class="error-bubble label bg-red margin-xs-t padding-xs-y"
                            >
                                <i class="fa-solid fa-triangle-exclamation" />
                                {{ T(clientNameError) }}
                            </span>
                        </div>
                    </div>

                    <p>
                        {{
                            T(
                                "Hinweis: Der eingegebene Gerätename wird nicht dauerhaft im Profil gespeichert. Er dient ausschließlich dazu, die oben angezeigte URL (für DoH) bzw. den Hostnamen (für DoT) temporär anzupassen. So kann dasselbe Profil auf mehreren Geräten verwendet werden – jeweils mit individuellem Namen zur besseren Identifikation in Statistiken und Protokollen."
                            )
                        }}
                    </p>
                </div>
            </tabs>
        </div>
    </tabs>
</template>

<script setup lang="ts">
import i18n, { T } from "@/classes/i18n"
import {
    ESecDnsType,
    type TSecureDns
} from "@/classes/objectTypes/mobileSecurity/secure-dns/secure-dns"
import miscQueries from "@/queries/misc"
import { useStore } from "@/store/vuex.store"
import Tabs, { type TabsEntry } from "@/templates/components/tabs.vue"
import { computed, onMounted, ref, watch } from "vue"

const props = defineProps<{
    properties: {
        item: TSecureDns
    }
}>()

const lang = ref(i18n.getLanguage())
const activeTab = ref("")
const activeBrowserTab = ref("chrome")
const tabsWithoutBox = new Set(["browser", "router"])

const name = ref(props.properties.item.name)
const id = ref(props.properties.item.id)
const shortId = ref(props.properties.item.endpoints.shortId)
const type = ref(props.properties.item.type)
const dohEndpoint = ref(props.properties.item.endpoints.doh)
const dotEndpoint = ref(props.properties.item.endpoints.dot)
const mobileConfigEndpoint = ref(props.properties.item.endpoints.mobileConfig)

const accountId = computed(() => {
    return useStore().state.session.activeAccountId || ""
})

const mobileConfigUrl = computed(() => {
    return `${mobileConfigEndpoint.value}?url=${dohClientName.value}`
})

const clientNameInput = ref("")
const clientNameError = ref("")

const dotClientName = computed(() => {
    let name = clientNameInput.value

    name = name.replaceAll(" ", "--")
    name = name.replace(/[^a-zA-Z0-9-]/g, "")

    if (name.length > 0) {
        name = name + "-"
    }

    return name + dotEndpoint.value
})

const dohClientName = computed(() => {
    const clientName = clientNameInput.value

    if (clientName.length === 0) {
        return dohEndpoint.value
    }

    return `${dohEndpoint.value}/${encodeURIComponent(clientNameInput.value)}`
})

const tabs = computed(() => {
    const _tabs: TabsEntry[] = []

    if (type.value === ESecDnsType.MDM) {
        _tabs.push({
            id: "mdm",
            text: "MDM",
            icon: "fas fa-mobile-screen",
            showHeaderInContent: false,
            onClick: () => {
                activeTab.value = "mdm"
            }
        })
    }

    if (type.value === ESecDnsType.EXTERNAL_DEVICES) {
        _tabs.push(
            {
                id: "app",
                text: T("Cloud Shield App"),
                icon: "fas fa-mobile-screen",
                showHeaderInContent: false,
                onClick: () => {
                    activeTab.value = "app"
                }
            },
            {
                id: "android",
                text: T("Android"),
                icon: "fab fa-android",
                showHeaderInContent: false,
                onClick: () => {
                    activeTab.value = "android"
                }
            },
            {
                id: "apple",
                text: T("Apple"),
                icon: "fab fa-apple",
                showHeaderInContent: false,
                onClick: () => {
                    activeTab.value = "apple"
                }
            },
            {
                id: "browser",
                text: T("Browser"),
                icon: "fab fa-chrome",
                showHeaderInContent: false,
                onClick: () => {
                    activeTab.value = "browser"
                }
            },
            {
                id: "other",
                text: T("Andere Geräte"),
                icon: "fas fa-router",
                showHeaderInContent: false,
                onClick: () => {
                    activeTab.value = "other"
                }
            }
        )
    }

    return _tabs
})

const browserTabs = ref([
    {
        id: "chrome",
        text: "Chrome",
        icon: "fab fa-chrome",
        showHeaderInContent: false,
        onClick: () => {
            activeBrowserTab.value = "chrome"
        }
    },
    {
        id: "firefox",
        text: "Firefox",
        icon: "fab fa-firefox",
        showHeaderInContent: false,
        onClick: () => {
            activeBrowserTab.value = "firefox"
        }
    },
    {
        id: "edge",
        text: "Edge",
        icon: "fab fa-edge",
        showHeaderInContent: false,
        onClick: () => {
            activeBrowserTab.value = "edge"
        }
    }
])

function download() {
    miscQueries.download(mobileConfigUrl.value, "securepoint-securedns.mobileconfig")
}

function openPlayStore() {
    window.open("https://play.google.com/store/apps/details?id=de.securepoint.securedns", "_blank")
}

function openAppStore() {
    window.open("https://apps.apple.com/de/app/securepoint-secure-dns/id6741109349", "_blank")
}

onMounted(() => {
    if (type.value === ESecDnsType.MDM) {
        activeTab.value = "mdm"
    } else {
        activeTab.value = "app"
    }
})

watch(clientNameInput, () => {
    if (clientNameInput.value.match(/- | - |--|^ +$|^ | $/)) {
        clientNameError.value = `Your input contains combinations of characters that may result in a different output in the statistics.`
    } else {
        clientNameError.value = ""
    }
})
</script>

<style scoped lang="scss">
.description {
    color: rgba(0, 0, 0, 0.5);
    font-size: 0.9em;
    margin: 0;

    .darkmode & {
        color: rgba(255, 255, 255, 0.5);
    }
}

.content-container {
    display: flex;
    align-items: flex-start;
    gap: 3rem;
    max-width: 1000px;
}

.qr-code {
    flex-shrink: 0;
    > {
        img {
            width: 220px;
            height: 220px;
        }
    }
}

.text-content {
    flex: 2;
    line-height: 1.6;

    > p {
        margin-bottom: 1rem;
    }
}

.highlight {
    background-color: rgba(255, 0, 0, 0.1);
    color: #e74c3c;
    padding: 0.25rem 0.5rem;
    border-radius: 0.2rem;
    font-weight: 500;
}

.store-buttons {
    display: flex;
    justify-content: center;
    align-items: center;
    margin: 20px 0;
}

ol {
    li {
        margin-bottom: 0.5rem;
    }
}
</style>
